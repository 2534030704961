import React, { useState } from 'react'
import { datePickerDefaultOptions } from '../../common/Constants'
import DatePicker from 'react-datepicker'
import classNames from 'classnames'
import moment from 'moment'
import { CUSTOM, EVERYTHING, PAST_7_DAYS, TODAY, YESTERDAY } from '../../teampro/Constants'
import I18n from 'i18n'
import Info from 'components/common/Info'

const ExportCombined = _props => {
  const [value, setValue] = useState(undefined)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [pseudonymize, setPseudonymize] = useState(false)
  const [pseudonymizeLevelTwo, setPseudonymizeLevelTwo] = useState(false)

  const downloadOptions = [
    { description: I18n.t('exports.download_options.today'), value: TODAY },
    { description: I18n.t('exports.download_options.yesterday'), value: YESTERDAY },
    { description: I18n.t('exports.download_options.past_7_days'), value: PAST_7_DAYS },
    { description: I18n.t('exports.download_options.everything'), value: EVERYTHING },
    { description: I18n.t('exports.download_options.custom'), value: CUSTOM }
  ]

  const constructDownload = () => {
    let start, end
    const today = new Date()
    switch (value) {
      case TODAY:
        start = today
        end = today
        break
      case YESTERDAY:
        start = moment(today).subtract(1, 'days').toDate()
        end = moment(today).subtract(1, 'days').toDate()
        break
      case PAST_7_DAYS:
        start = moment(today).subtract(7, 'days').toDate()
        end = today
        break
      case EVERYTHING:
        break
      case CUSTOM:
        start = startDate
        end = endDate
        break
    }
    const params = new URLSearchParams()
    if (start !== undefined && start !== null) params.append('start_date', start.toISOString())
    if (end !== undefined && end !== null) params.append('end_date', end.toISOString())
    const pseudonymizeLevel = calcPseudonymizeLevel()
    if (pseudonymizeLevel) params.append('pseudonymize', `${pseudonymizeLevel}`)
    return `/api/v1/responses/export_combined.csv?${params.toString()}`
  }

  const calcPseudonymizeLevel = () => {
    if (pseudonymize) {
      return pseudonymizeLevelTwo ? 2 : 1
    }
    return 0
  }

  const validToDownload = () => {
    if (value === undefined) return false
    if (value !== CUSTOM) return true
    return (startDate === null || endDate === null || moment(startDate) <= moment(endDate))
  }

  const showDownloadButton = validToDownload()

  return (
    <>
      <div className='row'>
        <div className='col s12'>
          <h5>
            {I18n.t('exports.export_combined')}
            {' '}
            <Info
              text={I18n.t('exports.combined_export_tooltip')}
              tooltipId='higher-z-tooltip'
              reuseTooltip
            />
          </h5>
        </div>
      </div>
      <div className='row no-margin-bottom'>
        <div className='col s12'>
          <h6>{I18n.t('exports.download_questionnaires_of')}</h6>
          {downloadOptions.map(option => (
            <p key={option.value}>
              <label>
                <input
                  name='export-questionnaires-combined'
                  type='radio'
                  value={option.value}
                  checked={value !== undefined && value === option.value}
                  onChange={e => setValue(e.target.value)}
                />
                <span>{option.description}</span>
              </label>
            </p>
          ))}
          {value === 'custom' && (
            <>
              <div className='row'>
                <div className='col s8 m3 input-field'>
                  <DatePicker
                    id='start-date' {...datePickerDefaultOptions}
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                  />
                  <label htmlFor='start-date' className={classNames({ active: startDate })}>{I18n.t('exports.start_date')}</label>
                </div>
                <div className='col s8 m3 input-field'>
                  <DatePicker
                    id='end-date' {...datePickerDefaultOptions}
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    maxDate={new Date()}
                  />
                  <label htmlFor='end-date' className={classNames({ active: endDate })}>{I18n.t('exports.end_date')}</label>
                </div>
              </div>
              <div className='row'>
                <div className='col s12'>
                  <p><em>{I18n.t('exports.start_and_end_not_required')}</em></p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col s12 input-field'>
          <p>
            <label>
              <input
                type='checkbox'
                checked={pseudonymize}
                onChange={e => setPseudonymize(e.target.checked)}
              />
              <span>
                {I18n.t('exports.pseudonymize_the_data')}
                {' '}
                <Info
                  text={I18n.t('exports.pseudonymize_tooltip')}
                  tooltipId='higher-z-tooltip'
                  reuseTooltip
                />
              </span>
            </label>
          </p>
        </div>
        {pseudonymize && (
          <div className='col s11 indented-control'>
            <p>
              <label>
                <input
                  type='checkbox'
                  checked={pseudonymizeLevelTwo}
                  onChange={e => setPseudonymizeLevelTwo(e.target.checked)}
                />
                <span>
                  {I18n.t('exports.pseudonymize_level_two')}
                </span>
              </label>
            </p>
          </div>
        )}
      </div>
      <div className='row'>
        <div className='col s12'>
          {showDownloadButton && (
            <a className='waves-effect waves-light btn download-button' href={constructDownload()}>
              <i className='material-icons left'>file_download</i>
              {I18n.t('exports.download')}
            </a>
          )}
          {!showDownloadButton && (
            <button className='waves-effect waves-light btn download-button' disabled>
              <i className='material-icons left'>file_download</i>
              {I18n.t('exports.download')}
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default ExportCombined
