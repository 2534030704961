import React, { useState } from 'react'
import { MyAxios as axios } from '../MyAxios'
import ExportsRoutes from './ExportsRoutes'
import { WithSession } from '../session/SessionProvider'
import { WithFlashMessages } from '../flashmessages/FlashMessageProvider'
import { capitalizeFirstLetter, smartTranslate } from '../common/Utils'

const ExportsController = props => {
  const [teams, setTeams] = useState([])

  const fetchTeams = () => {
    const result = getTeams()
    result
      .then(res => { setTeams(res.data) })
      .catch(err => {
        const errorTitles = Object.entries(err.response.data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k)}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
        props.flashMessages.push(
          errorTitles,
          props.flashMessages.duration.LONG,
          props.flashMessages.levels.ERROR)
      })
  }

  const getTeams = () => {
    return axios.get('/api/v1/teams', { headers: { ...props.authorizationHeaders() } })
  }

  return (
    <ExportsRoutes
      exportDataProps={{
        teams,
        fetchTeams
      }}
    />
  )
}

export default WithFlashMessages(WithSession(ExportsController))
